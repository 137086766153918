import assignScheduledOrderNowMutation from 'service/mutations/verd/assignScheduledOrderNow';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { apiMutate } from 'service/utils/mutation';

type useAssignDriverToOrderType = () => (variables: any) => any;

const useAssignScheduledOrderNow: useAssignDriverToOrderType = () => variables =>
  apiMutate({
    mutation: assignScheduledOrderNowMutation,
    variables,
    clientName: GRAPHQL_CLIENT_NAMES.VERD,
    requestHeaders: {},
    handler: (res: any) => res,
  });

export default useAssignScheduledOrderNow;
