import { gql } from 'graphql-request';

const assignScheduledOrderNowMutation = gql`
  mutation($orderNumber: String!, $userId: Int!) {
    assignScheduledNow(orderNumber: $orderNumber, userId: $userId) {
      order {
        number
      }
    }
  }
`;

export default assignScheduledOrderNowMutation;
