import React, { useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { THIRD_PARTY_TYPE_ENUM } from 'utils/enums';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import { context as userContext } from 'context/user';
import OrderDeliveryConfirmation from '../../../OrderDeliveryConfirmation';
import { MerchantDeliveryActionButtons, OnFleetDeliveryActionButtons } from './OrderDetailsStatus';
import {
  calculateNewExpectedAt,
  hasNewExpectedAt as hasNewExpectedAtHelper,
  isExpectedAtValid as isExpectedAtValidHelper,
  isDeliveryOrder as isDeliveryOrderHelper,
} from '../helpers';

const OrderDetailsActionButtons = ({
  order,
  hasCourier,
  isUpdatingStatus,
  shouldDisableActionBtns,
  sendChangeStatus,
  open,
  close,
  updatedCourierId,
  selectedStore,
  newExpectedAt,
  deliveryType,
}) => {
  const { expectedAt: orderExpectedAt, id: orderId, deliveryType: orderDeliveryType } = order || {};
  const { lang } = useContext(localeContext);
  const {
    selectedStore: { restaurantCourierSetting },
    settings,
  } = useContext(userContext);
  const hasNewExpectedAt = order && hasNewExpectedAtHelper({ order, hasCourier, newExpectedAt });
  const isExpectedAtValid = order && isExpectedAtValidHelper({ order, hasCourier, newExpectedAt });
  const isDeliveryOrder = order && isDeliveryOrderHelper(orderDeliveryType);
  const isVerd = settings.enableVerd;
  const filteredRestaurantCourierSetting = restaurantCourierSetting.filter(
    courier => !courier.isInternalDelivery && courier.businessType === THIRD_PARTY_TYPE_ENUM.Delivery,
  );
  const isArabic = lang === 'ar';
  const orderDeliveryCourierName = isArabic
    ? order?.deliveryCourier?.courierDetails?.displayNameAr || order?.deliveryCourier?.courierDetails?.name
    : order?.deliveryCourier?.courierDetails?.displayNameEn || order?.deliveryCourier?.courierDetails?.name;
  const orderDeliveryCourierSupportNumber = order?.deliveryCourier?.courierDetails?.supportNumber;
  const deliveryOrderStatus = order?.deliveryCourier?.deliveryOrderStatus;
  const isOrderDeliveryCourierNotVerd = !order?.deliveryCourier?.isInternalDelivery;
  const isCourierNotDeclinedTheRequest =
    isOrderDeliveryCourierNotVerd && deliveryOrderStatus !== ORDER_RIDER_STATUS.DECLINED;

  const changeFrom = moment(orderExpectedAt)
    .tz(selectedStore.timeZone)
    .locale(lang === 'ar' ? 'ar' : 'en-gb')
    .format('hh:mm A');
  const changeTo = calculateNewExpectedAt(order)
    .tz(selectedStore.timeZone)
    .locale(lang === 'ar' ? 'ar' : 'en-gb')
    .format('hh:mm A');

  const openOrderDeliveryConfirmationPopUp = ({
    assignDriverToOrder,
    assignee,
    isAssigneeSupportCancellation,
    isUnAssignCourier,
    acceptOrder,
  }) =>
    open({
      title: <Text value={translations.CONFIRM_CANCELLATION} className="text-xl" />,
      body: (
        <OrderDeliveryConfirmation
          orderId={orderId}
          assignDriverToOrder={assignDriverToOrder}
          updatedCourierId={updatedCourierId}
          acceptOrder={acceptOrder}
          sendChangeStatus={sendChangeStatus}
          onCancel={close}
          from={changeFrom}
          to={changeTo}
          isExpectedAtValid={isExpectedAtValid}
          isCourierNotDeclinedTheRequest={isCourierNotDeclinedTheRequest}
          orderDeliveryCourierName={orderDeliveryCourierName}
          orderDeliveryCourierSupportNumber={orderDeliveryCourierSupportNumber}
          isUnAssignCourier={isUnAssignCourier}
          isAssigneeSupportCancellation={isAssigneeSupportCancellation}
          assignee={assignee}
          newExpectedAt={hasNewExpectedAt ? newExpectedAt : null}
          timeZone={selectedStore.timeZone}
          expectedAtDateFormat={
            newExpectedAt && moment(newExpectedAt).isSame(orderExpectedAt, 'day') ? 'hh:mm A' : 'DD MMM YYYY hh:mm A'
          }
        />
      ),
      size: 'max-w-xl',
    });

  const onFleetCourierActions = (
    <OnFleetDeliveryActionButtons
      order={order}
      isUpdatingStatus={isUpdatingStatus}
      shouldDisableActionBtns={shouldDisableActionBtns}
      sendChangeStatus={sendChangeStatus}
      deliveryType={deliveryType}
      openOrderDeliveryConfirmationPopUp={openOrderDeliveryConfirmationPopUp}
      isExpectedAtValid={isExpectedAtValid}
    />
  );

  const noCourierActions = (
    <MerchantDeliveryActionButtons
      order={order}
      isUpdatingStatus={isUpdatingStatus}
      shouldDisableActionBtns={shouldDisableActionBtns}
      sendChangeStatus={sendChangeStatus}
      deliveryType={deliveryType}
    />
  );

  if (isDeliveryOrder && (isVerd || filteredRestaurantCourierSetting.length)) return onFleetCourierActions;

  return noCourierActions;
};

OrderDetailsActionButtons.propTypes = {
  order: PropTypes.shape({
    expectedAt: PropTypes.string.isRequired,
    deliveryType: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  hasCourier: PropTypes.bool.isRequired,
  isUpdatingStatus: PropTypes.bool.isRequired,
  shouldDisableActionBtns: PropTypes.bool,
  sendChangeStatus: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  updatedCourierId: PropTypes.string,
  selectedStore: PropTypes.shape({ timeZone: PropTypes.string }).isRequired,
  newExpectedAt: PropTypes.string,
  deliveryType: PropTypes.string.isRequired,
};
export default OrderDetailsActionButtons;
