import React, { useContext } from 'react';
import cx from 'classnames';
import { Tooltip, Typography } from '@zydalabs/zac-react';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import { GENERATED_SALES, LAST_30_DAYS } from 'constants/translations';
import SalesLineChart from './SalesLineChart';

const GeneratedSales = () => {
  const { lang, direction, translate } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const { currency } = selectedStore;

  return (
    <div className="bg-gray-100 p-4 h-full" dir={direction}>
      <div className="flex justify-between flex-wrap gap-4">
        <div>
          <div className="mb-4">
            <div className="flex justify-start items-center">
              <Typography variant="heading14">
                <Text value={GENERATED_SALES} className={cx('w-auto', lang === 'ar' ? 'ml-2' : 'mr-2')} />
              </Typography>

              <div className="flex-shrink-0">
                <Tooltip
                  info={<Text className="p-2" value={['info for tooltip', 'info for tooltip']} />}
                  size="small"
                  placement="bottom"
                />
              </div>
            </div>
            <Typography variant="body12">
              <Text value={LAST_30_DAYS} />
            </Typography>
          </div>
          <Typography variant="heading24">
            <div style={{ direction }}>
              <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
              {(500.02).toFixed(currency.decimals)}
            </div>
          </Typography>
        </div>
        <div className="w-full md:w-60">
          <SalesLineChart />
        </div>
      </div>
    </div>
  );
};

export default GeneratedSales;
