import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Typography } from '@zydalabs/zac-react';

import { Text } from 'components/service';

const SalesLineChart = () => (
  <>
    <div className="h-24">
      <ResponsiveLine
        curve="linear"
        enableArea
        enablePoints={false}
        enableGridX={false}
        enableGridY={false}
        colors={{ scheme: 'accent' }}
        data={[
          {
            data: [
              { x: 0, y: 7 },
              { x: 1, y: 5 },
              { x: 2, y: 11 },
              { x: 3, y: 12 },
              { x: 4, y: 13 },
              { x: 5, y: 20 },
              { x: 6, y: 18 },
              { x: 7, y: 16 },
              { x: 8, y: 8 },
              { x: 9, y: 10 },
              { x: 10, y: 9 },
            ],
          },
        ]}
      />
    </div>
    <div className="flex items-center justify-between mt-3">
      <Typography variant="body12">
        <Text value={['Dec 1', 'Dec 1']} />
      </Typography>
      <Typography variant="body12">
        <Text value={['Jan 30', 'Jan 30']} />
      </Typography>
    </div>
  </>
);

export default SalesLineChart;
