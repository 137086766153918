import React, { useContext } from 'react';
import cx from 'classnames';
import { Typography } from '@zydalabs/zac-react';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import { CONVERSATIONS_COUNT, LAST_30_DAYS, LOST, ORDERS, QUEUED, SENT } from 'constants/translations';

const ConversationCount = () => {
  const { lang } = useContext(localeContext);

  return (
    <div className="bg-gray-100 p-4 h-full">
      <div className="mb-4">
        <Typography variant="heading14">
          <Text value={CONVERSATIONS_COUNT} />
        </Typography>
        <Typography variant="body12">
          <Text value={LAST_30_DAYS} />
        </Typography>
      </div>
      <div className="flex items-center">
        <div className={cx('w-1/4 text-center border-gray-300', lang === 'ar' ? 'border-l' : 'border-r')}>
          <Typography variant="heading24">
            <Text value={['32', '32']} />
          </Typography>
          <Typography variant="body12">
            <Text value={SENT} />
          </Typography>
        </div>
        <div className={cx('w-1/4 text-center border-gray-300', lang === 'ar' ? 'border-l' : 'border-r')}>
          <Typography variant="heading24">
            <Text value={['10', '10']} />
          </Typography>
          <Typography variant="body12">
            <Text value={ORDERS} />
          </Typography>
        </div>
        <div className={cx('w-1/4 text-center border-gray-300', lang === 'ar' ? 'border-l' : 'border-r')}>
          <Typography variant="heading24">
            <Text value={['1', '1']} />
          </Typography>
          <Typography variant="body12">
            <Text value={LOST} />
          </Typography>
        </div>
        <div className="w-1/4 text-center">
          <Typography variant="heading24">
            <Text value={['12', '12']} />
          </Typography>
          <Typography variant="body12">
            <Text value={QUEUED} />
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ConversationCount;
