import React from 'react';
import * as yup from 'yup';

import { Text } from 'components/service';
import * as translations from 'constants/translations';

const bulkValidationSchema = yup.object().shape({
  deliveryFee: yup.number().when('needsDeliveryFee', {
    is: true,
    then: yup
      .number()
      .required(<Text value={translations.DELIVERY_FEES_REQ} />)
      .min(0, <Text value={translations.GREATER_THAN_OR_EQUAL_ZERO} />)
      .typeError(<Text value={translations.NUMBERS_ONLY} />),
  }),
  minimumOrder: yup.number().when('needsMinimumOrder', {
    is: true,
    then: yup
      .number()
      .required(<Text value={translations.MIN_ORDER_REQ} />)
      .min(0, <Text value={translations.GREATER_THAN_OR_EQUAL_ZERO} />)
      .typeError(<Text value={translations.NUMBERS_ONLY} />),
  }),
  deliveryTime: yup.number().when('needsDeliveryTime', {
    is: true,
    then: yup
      .number()
      .required(<Text value={translations.DELIVERY_TIME_REQ} />)
      .integer(<Text value={translations.DELIVERY_TIME_INTEGER} />)
      .min(1, <Text value={translations.GREATER_THAN_ZERO} />)
      .typeError(<Text value={translations.NUMBERS_ONLY} />),
  }),
});

export default bulkValidationSchema;
