import React from 'react';
import cx from 'classnames';

import {
  ADD_RESTAURANT_LOGO,
  DOWNLOAD_QR_CODE,
  QR_CODE_STYLE,
  RESTAURANT_LOGO,
  SELECT_QR_CODE_STYLE,
  SHOW_LOGO_IN_QR,
} from 'constants/translations';
import { Button, Checkbox, Typography, useTheme } from '@zydalabs/zac-react';
import QRCodeSVGStyle1 from 'assets/QRCode/qr-code-1.svg';
import QRCodeSVGStyle2 from 'assets/QRCode/qr-code-2.svg';
import QRCodeSVGStyle3 from 'assets/QRCode/qr-code-3.svg';
import QRCodeSVGStyle4 from 'assets/QRCode/qr-code-4.svg';
import { DownloadIcon } from '@zydalabs/zac-icons-react';
import { DotType } from 'qr-code-styling';

type QRCodeStyleProps = {
  translate(item: Array<string>): string;
  handleOnChangeSize(size: DotType): void;
  buttonSelected: DotType;
  isChecked: boolean;
  setIsChecked(flag: boolean): void;
  onDownloadClick(): void;
  qrCodeClasses: string;
};

const QRCodeStyle: React.FC<QRCodeStyleProps> = ({
  translate,
  handleOnChangeSize,
  buttonSelected,
  setIsChecked,
  isChecked,
  onDownloadClick,
  qrCodeClasses,
}) => {
  const { colors } = useTheme();
  return (
    <div className="pb-6">
      <div style={{ backgroundColor: colors.positive.background }} className="p-6">
        <div className="flex flex-col">
          <Typography variant="heading16">{translate(QR_CODE_STYLE)}</Typography>
          <Typography variant="body14">{translate(SELECT_QR_CODE_STYLE)}</Typography>
        </div>

        <div className="py-4">
          <div className="flex flex-row">
            <div
              className={qrCodeClasses}
              role="presentation"
              onClick={e => {
                e.preventDefault();
                handleOnChangeSize('square');
              }}
            >
              <img
                className={cx('bg-white rounded-xl', buttonSelected === 'square' && 'border border-blue-500')}
                src={QRCodeSVGStyle1}
                alt="qr-code-squared"
                width={182.25}
                height={181}
              />
            </div>
            <div
              className={qrCodeClasses}
              role="presentation"
              onClick={e => {
                e.preventDefault();
                handleOnChangeSize('dots');
              }}
            >
              <img
                className={cx('bg-white rounded-xl', buttonSelected === 'dots' && 'border border-blue-500')}
                src={QRCodeSVGStyle2}
                alt="qr-code-dots"
                width={182.25}
                height={181}
              />
            </div>
            <div
              className={qrCodeClasses}
              role="presentation"
              onClick={e => {
                e.preventDefault();
                handleOnChangeSize('rounded');
              }}
            >
              <img
                className={cx('bg-white rounded-xl', buttonSelected === 'rounded' && 'border border-blue-500')}
                src={QRCodeSVGStyle3}
                alt="qr-code-rounded"
                width={182.25}
                height={181}
              />
            </div>
            <div
              className={qrCodeClasses}
              role="presentation"
              onClick={e => {
                e.preventDefault();
                handleOnChangeSize('classy-rounded');
              }}
            >
              <img
                className={cx('bg-white rounded-xl', buttonSelected === 'classy-rounded' && 'border border-blue-500')}
                src={QRCodeSVGStyle4}
                alt="qr-code-classy-rounded"
                width={182.25}
                height={181}
              />
            </div>
          </div>
          <div className="pt-4 pb-8">
            <div className="flex flex-col">
              <Typography variant="heading16">{translate(RESTAURANT_LOGO)}</Typography>
              <Typography variant="body14">{translate(ADD_RESTAURANT_LOGO)}</Typography>
              <Checkbox
                isChecked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                label={translate(SHOW_LOGO_IN_QR)}
              />
            </div>

            <Button
              endIcon={<DownloadIcon width={26} height={16} />}
              rounded
              onClick={onDownloadClick}
              text={translate(DOWNLOAD_QR_CODE)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRCodeStyle;
