import {
  customers,
  analytics,
  salesAnalytics,
  behaviorBreakdown,
  bestSelling,
  geoAnalytics,
  menuOverview,
  variantItemTracking,
  itemTracking,
  createCampaign,
  socialmediatracking,
  ordersManagementScheduling,
  paymentGateways,
  branchEdition,
  integrations,
  branchCreation,
} from 'paths';
import { LAYOUT_ACCESS_MATRIX } from 'constants/translations';

import CRMDesktop from 'assets/featureBlocking/CRMDesktop.png';
import CRMMobile from 'assets/featureBlocking/CRMMobile.png';
import BehaviorBreakdownDesktop from 'assets/featureBlocking/BehaviorBreakdownDesktop.png';
import BehaviorBreakdownMobile from 'assets/featureBlocking/BehaviorBreakdownMobile.png';
import BestSellingDesktop from 'assets/featureBlocking/BestSellingDesktop.png';
import BestSellingMobile from 'assets/featureBlocking/BestSellingMobile.png';
import BranchesAndDeliveryDesktop from 'assets/featureBlocking/BranchesAndDeliveryDesktop.png';
import BranchesAndDeliveryMobile from 'assets/featureBlocking/BranchesAndDeliveryMobile.png';
import SalesAnalyticsDesktop from 'assets/featureBlocking/SalesAnalyticsDesktop.png';
import SalesAnalyticsMobile from 'assets/featureBlocking/SalesAnalyticsMobile.png';

export const STORE_ID_PATH_STRING = ':storeId';
export const BRANCH_ID_PATH_STRING = ':branchId';

export const ACCESS_MAP_KEYS = {
  SIDE: 'side',
  LAYOUT: 'layout',
  FEATURES: 'features',
};

export const FREE_PLAN_ACCESS_MAP = {
  [ACCESS_MAP_KEYS.SIDE]: [
    [
      customers,
      {
        isBlocked: true,
      },
    ],
    [
      analytics,
      {
        isBlocked: true,
      },
    ],
    [
      salesAnalytics,
      {
        isBlocked: true,
      },
    ],
    [
      behaviorBreakdown,
      {
        isBlocked: true,
      },
    ],
    [
      bestSelling,
      {
        isBlocked: true,
      },
    ],
    [
      geoAnalytics,
      {
        isBlocked: true,
      },
    ],
  ],
  [ACCESS_MAP_KEYS.LAYOUT]: [
    [
      customers,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureList,
        featureDesktopImage: CRMDesktop,
        featureMobileImage: CRMMobile,
      },
    ],
    [
      salesAnalytics,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.SALES_ANALYTICS.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.SALES_ANALYTICS.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.SALES_ANALYTICS.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.SALES_ANALYTICS.featureList,
        featureDesktopImage: SalesAnalyticsDesktop,
        featureMobileImage: SalesAnalyticsMobile,
      },
    ],
    [
      behaviorBreakdown,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureList,
        featureDesktopImage: BehaviorBreakdownDesktop,
        featureMobileImage: BehaviorBreakdownMobile,
      },
    ],
    [
      bestSelling,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureList,
        featureDesktopImage: BestSellingDesktop,
        featureMobileImage: BestSellingMobile,
      },
    ],
    [
      geoAnalytics,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureList,
        featureDesktopImage: BranchesAndDeliveryDesktop,
        featureMobileImage: BranchesAndDeliveryMobile,
      },
    ],
  ],
  [ACCESS_MAP_KEYS.FEATURES]: [
    [
      menuOverview,
      {
        ITEM_TRACKING: {
          isBlocked: true,
        },
      },
    ],
    [
      variantItemTracking,
      {
        ITEM_TRACKING: {
          isBlocked: true,
        },
      },
    ],
    [
      itemTracking,
      {
        ITEM_TRACKING: {
          isBlocked: true,
        },
      },
    ],
    [
      createCampaign,
      {
        SEGMENT_TARGETING: {
          isBlocked: true,
        },
      },
    ],
    [
      socialmediatracking,
      {
        GTM: {
          isBlocked: true,
        },
        FACEBOOK_PIXEL: {
          isBlocked: true,
        },
        FACEBOOK_BUSINESS_MANAGER: {
          isBlocked: true,
        },
      },
    ],
    [
      ordersManagementScheduling,
      {
        SCHEDULING: {
          isBlocked: true,
        },
      },
    ],
    [
      paymentGateways,
      {
        CASH_ON_DELIVERY: {
          isBlocked: true,
        },
        CARD_ON_DELIVERY: {
          isBlocked: true,
        },
      },
    ],
    [
      branchCreation,
      {
        PICKUP: {
          isBlocked: true,
        },
      },
    ],
    [
      branchEdition,
      {
        PICKUP: {
          isBlocked: true,
        },
      },
    ],
    [
      integrations,
      {
        FOODICS: {
          isBlocked: true,
        },
        DELIVERECT: {
          isBlocked: true,
        },
        OCIMS: {
          isBlocked: true,
        },
      },
    ],
  ],
};

export const STANDARD_PLAN_ACCESS_MAP = {
  [ACCESS_MAP_KEYS.SIDE]: [
    [
      customers,
      {
        isBlocked: true,
      },
    ],
    [
      analytics,
      {
        isBlocked: false,
      },
    ],
    [
      salesAnalytics,
      {
        isBlocked: false,
      },
    ],
    [
      behaviorBreakdown,
      {
        isBlocked: true,
      },
    ],
    [
      bestSelling,
      {
        isBlocked: true,
      },
    ],
    [
      geoAnalytics,
      {
        isBlocked: true,
      },
    ],
  ],
  [ACCESS_MAP_KEYS.LAYOUT]: [
    [
      customers,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureList,
        featureDesktopImage: CRMDesktop,
        featureMobileImage: CRMMobile,
      },
    ],
    [
      behaviorBreakdown,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureList,
        featureDesktopImage: BehaviorBreakdownDesktop,
        featureMobileImage: BehaviorBreakdownMobile,
      },
    ],
    [
      bestSelling,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureList,
        featureDesktopImage: BestSellingDesktop,
        featureMobileImage: BestSellingMobile,
      },
    ],
    [
      geoAnalytics,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureList,
        featureDesktopImage: BranchesAndDeliveryDesktop,
        featureMobileImage: BranchesAndDeliveryMobile,
      },
    ],
  ],
  [ACCESS_MAP_KEYS.FEATURES]: [
    [
      menuOverview,
      {
        ITEM_TRACKING: {
          isBlocked: false,
        },
      },
    ],
    [
      variantItemTracking,
      {
        ITEM_TRACKING: {
          isBlocked: false,
        },
      },
    ],
    [
      itemTracking,
      {
        ITEM_TRACKING: {
          isBlocked: false,
        },
      },
    ],
    [
      createCampaign,
      {
        SEGMENT_TARGETING: {
          isBlocked: true,
        },
      },
    ],
    [
      socialmediatracking,
      {
        GTM: {
          isBlocked: true,
        },
        FACEBOOK_PIXEL: {
          isBlocked: false,
        },
        FACEBOOK_BUSINESS_MANAGER: {
          isBlocked: false,
        },
      },
    ],
    [
      ordersManagementScheduling,
      {
        SCHEDULING: {
          isBlocked: false,
        },
      },
    ],
    [
      paymentGateways,
      {
        CASH_ON_DELIVERY: {
          isBlocked: false,
        },
        CARD_ON_DELIVERY: {
          isBlocked: false,
        },
      },
    ],
    [
      branchCreation,
      {
        PICKUP: {
          isBlocked: false,
        },
      },
    ],
    [
      branchEdition,
      {
        PICKUP: {
          isBlocked: false,
        },
      },
    ],
    [
      integrations,
      {
        FOODICS: {
          isBlocked: false,
        },
        DELIVERECT: {
          isBlocked: true,
        },
        OCIMS: {
          isBlocked: true,
        },
      },
    ],
  ],
};

export const GROWTH_PLAN_ACCESS_MAP = {
  [ACCESS_MAP_KEYS.SIDE]: [
    [
      customers,
      {
        isBlocked: false,
      },
    ],
    [
      analytics,
      {
        isBlocked: false,
      },
    ],
    [
      salesAnalytics,
      {
        isBlocked: false,
      },
    ],
    [
      behaviorBreakdown,
      {
        isBlocked: false,
      },
    ],
    [
      bestSelling,
      {
        isBlocked: false,
      },
    ],
    [
      geoAnalytics,
      {
        isBlocked: false,
      },
    ],
  ],
  [ACCESS_MAP_KEYS.LAYOUT]: [
    [
      customers,
      {
        isBlocked: false,
        featureTitle: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureList,
        featureDesktopImage: CRMDesktop,
        featureMobileImage: CRMMobile,
      },
    ],
    [
      behaviorBreakdown,
      {
        isBlocked: false,
        featureTitle: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureList,
        featureDesktopImage: BehaviorBreakdownDesktop,
        featureMobileImage: BehaviorBreakdownMobile,
      },
    ],
    [
      bestSelling,
      {
        isBlocked: false,
        featureTitle: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureList,
        featureDesktopImage: BestSellingDesktop,
        featureMobileImage: BestSellingMobile,
      },
    ],
    [
      geoAnalytics,
      {
        isBlocked: false,
        featureTitle: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureList,
        featureDesktopImage: BranchesAndDeliveryDesktop,
        featureMobileImage: BranchesAndDeliveryMobile,
      },
    ],
  ],
  [ACCESS_MAP_KEYS.FEATURES]: [
    [
      menuOverview,
      {
        ITEM_TRACKING: {
          isBlocked: false,
        },
      },
    ],
    [
      variantItemTracking,
      {
        ITEM_TRACKING: {
          isBlocked: false,
        },
      },
    ],
    [
      itemTracking,
      {
        ITEM_TRACKING: {
          isBlocked: false,
        },
      },
    ],
    [
      createCampaign,
      {
        SEGMENT_TARGETING: {
          isBlocked: false,
        },
      },
    ],
    [
      socialmediatracking,
      {
        GTM: {
          isBlocked: false,
        },
        FACEBOOK_PIXEL: {
          isBlocked: false,
        },
        FACEBOOK_BUSINESS_MANAGER: {
          isBlocked: false,
        },
      },
    ],
    [
      ordersManagementScheduling,
      {
        SCHEDULING: {
          isBlocked: false,
        },
      },
    ],
    [
      paymentGateways,
      {
        CASH_ON_DELIVERY: {
          isBlocked: false,
        },
        CARD_ON_DELIVERY: {
          isBlocked: false,
        },
      },
    ],
    [
      branchCreation,
      {
        PICKUP: {
          isBlocked: false,
        },
      },
    ],
    [
      branchEdition,
      {
        PICKUP: {
          isBlocked: false,
        },
      },
    ],
    [
      integrations,
      {
        FOODICS: {
          isBlocked: false,
        },
        DELIVERECT: {
          isBlocked: false,
        },
        OCIMS: {
          isBlocked: false,
        },
      },
    ],
  ],
};

export const PLAN_TYPES = {
  FREE: 'free',
  STANDARD: 'standard',
  GROWTH: 'growth',
};
