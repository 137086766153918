import React, { useContext } from 'react';
import cx from 'classnames';
import { Typography } from '@zydalabs/zac-react';
import { BookClosedIcon, MenuIcon, MessageBubbleBottomMiddleIcon } from '@zydalabs/zac-icons-react';
import { useLocation } from '@reach/router';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { context as featureBlockingContext } from 'context/feature-blocking';
import { useMobile } from 'hooks/index';
import { Text } from 'components/service';
import { ACCESS_MAP_KEYS } from 'constants/featureAccessMatrix';
import FeatureAccess from 'common/lib/FeatureAccess';

const Breadcrumbs = ({
  customTitle = '',
  path,
  right = undefined,
  helperLinks = undefined,
  children = undefined,
  className = '',
  menuTop = false,
  refLink = '',
  isOverview = false,
  reverse = false,
  productTour = false,
}) => {
  const length = path && path[0].length - 1;
  const { lang } = useContext(localeContext);
  const { setSidebarOpened, isNavBarCollapsed, setCollapseNavBar, selectedStore } = useContext(userContext);
  const isMobile = useMobile();
  const menuItemTrackingTop = menuTop ? 'flex-row justify-between items-center' : 'flex-col sm:items-center';

  const { featureAccess } = useContext(featureBlockingContext);
  const featureFeatureAccess = featureAccess.get(ACCESS_MAP_KEYS.LAYOUT);

  const location = useLocation();
  const { isBlocked } =
    featureFeatureAccess?.get(FeatureAccess.getFeaturePath(location?.pathname, selectedStore.id)) || false;

  return (
    <div className="z-10 sticky top-0 bg-white">
      <div
        className={cx(
          'flex mt-4 h-auto md:h-12 xl:mt-5 xl:px-6 px-4',
          className ? className : menuItemTrackingTop,
          lang === 'ar'
            ? 'sm:flex-row-reverse ' + (reverse && ' md:flex-row-reverse') + (isOverview ? ' md:flex-row' : 'items-end')
            : 'sm:flex-row items-start',
        )}
      >
        <div className={cx(refLink && 'w-full md:w-1/2')}>
          <div className={cx('flex flex-row w-full items-center', lang === 'ar' && 'flex-row-reverse')}>
            {isMobile && (
              <button
                data-testid="mobile-sidebar"
                className={cx('material-icons text-black', lang === 'en' ? 'mr-2' : 'ml-2')}
                onClick={e => {
                  e.preventDefault();
                  isMobile && setSidebarOpened(true);
                }}
              >
                menu
              </button>
            )}
            {!isMobile && (
              <div
                className={cx('py-2 cursor-pointer', lang === 'en' ? 'pr-2' : 'pl-2')}
                onClick={() => setCollapseNavBar(!isNavBarCollapsed)}
              >
                <MenuIcon width="24" height="22" />
              </div>
            )}
            {customTitle ? (
              customTitle
            ) : (
              <Typography variant="heading24">
                <Text value={[path[0][length], path[1][length]]} />
              </Typography>
            )}
            {refLink && refLink}
          </div>
        </div>
        {right && !isMobile && !isBlocked && (
          <div className={cx('flex flex-row mt-2 md:mt-0', lang === 'ar' ? 'md:mr-auto' : 'md:ml-auto')}>{right}</div>
        )}
      </div>
      {children && children}
      {helperLinks && (
        <div className={cx('flex flex-wrap', lang === 'en' ? 'flex-row' : 'flex-row-reverse')}>
          <span
            className={cx(
              lang === 'en' ? 'flex-row' : !isOverview ? 'flex-row-reverse' : 'row-reverse',
              'flex xl:px-6 px-4',
            )}
          >
            <BookClosedIcon width="14px" color="text-gray-600" />
            <a
              href={helperLinks.link[lang === 'en' ? 0 : 1]}
              target="_blank"
              className="w-fit cursor-pointer text-sm px-1 text-gray-600"
            >
              {helperLinks.text[lang === 'en' ? 0 : 1]}
            </a>
          </span>

          {productTour && (
            <span className={cx(lang === 'en' ? 'flex-row' : 'row-reverse', 'flex xl:px-6 px-4')}>
              <MessageBubbleBottomMiddleIcon width="14px" color="text-gray-600" />
              <a href={productTour.link} target="_blank" className="w-fit cursor-pointer text-sm px-1 text-gray-600">
                {productTour.text}
              </a>
            </span>
          )}
        </div>
      )}
      {right && isMobile && !isBlocked && (
        <div
          className={cx(
            'flex flex-row mt-2 md:mt-0 py-2 px-4',
            lang === 'ar' ? 'flex-row-reverse md:mr-auto' : 'md:ml-auto',
          )}
        >
          {right}
        </div>
      )}
      <div className="mt-3 border-b border-gray-300" />
    </div>
  );
};

export default Breadcrumbs;
