import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { CheckboxAlt } from 'components/form/elements';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import * as userService from 'services/user';
import * as schemas from 'components/service/Page/schemas';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { enableVerd } from 'rest';

const EnableVerdSetting = () => {
  const { settings, branches, setSettings, selectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const branchesIds = branches.map(branch => branch.id);
  const [enableVerdCheckbox, setEnableVerdCheckbox] = useState(settings?.enableVerd);
  const [loading, setLoading] = useState(false);

  const { refetch } = useQuery(schemas.USER, {
    variables: {
      storeId: selectedStore.id,
      id: userService.getUserId(),
    },
    onCompleted: data => {
      setSettings(data.settings);
      setLoading(false);
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setEnableVerdCheckbox(settings?.enableVerd);
  }, [settings?.enableVerd]);

  const handleVerdChecbox = () => {
    setLoading(true);
    setEnableVerdCheckbox(!enableVerdCheckbox);
    enableVerd(selectedStore, branchesIds, !settings?.enableVerd)
      .then(() => refetch())
      .catch(error => {
        setEnableVerdCheckbox(settings?.enableVerd);
        if (error.response?.data.external_response) {
          const formattedError = JSON.parse(error.response.data.external_response).restaurant_courier_settings?.[0];
          notifications.show(formattedError, 'error');
        } else {
          notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
        }
      });
  };

  return (
    <CheckboxAlt
      title={<Text value={translations.ENABLE_VERD} />}
      name="enableVERD"
      disabled={loading}
      value={enableVerdCheckbox}
      onChange={handleVerdChecbox}
    />
  );
};

export default EnableVerdSetting;
