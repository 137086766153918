import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import {
  DISCOUNT,
  COUPON_DISCOUNT,
  SUBTOTAL_AFTER_VOUCHER,
  DELIVERY_FEES,
  TAXES,
  TAX_ARE_INCLUDED,
  TOTAL,
  PAYMENT_ORDER,
} from 'constants/translations';
import DELIVERY_TYPE_ENUM from '../A4OrderInvoice/utils';

const ThermalInvoicePricing = ({ order, isGift, direction, translate, lang, store, paidThroughMethod }) => {
  const {
    discountedAmount,
    voucherCode,
    subtotalAfterVoucher,
    deliveryType,
    deliveryFee,
    tax,
    taxPercentage,
    taxInclusive,
    total,
    paidThrough,
    voucherAmount,
  } = order || {};
  const { currency } = store || {};

  return (
    <div>
      {discountedAmount > 0 && !isGift && (
        <div className={cx('flex justify-between w-full pb-1', lang === 'ar' && 'flex-row-reverse')}>
          <span className="text-xs">{translate(DISCOUNT)}</span>
          <div className="text-xs" style={{ direction }}>
            <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>-
            {discountedAmount.toFixed(currency.decimals)}
          </div>
        </div>
      )}

      {voucherCode && !isGift && (
        <>
          <div className={cx('flex justify-between w-full pb-1', lang === 'ar' && 'flex-row-reverse')}>
            <span className="text-xs" style={{ direction }}>
              {translate(COUPON_DISCOUNT)}({voucherCode})
            </span>
            <div className="text-xs" style={{ direction }}>
              <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>-
              {voucherAmount.toFixed(currency.decimals)}
            </div>
          </div>

          <div className={cx('flex justify-between w-full pb-1 pt-2', lang === 'ar' && 'flex-row-reverse')}>
            <span className="text-sm">{translate(SUBTOTAL_AFTER_VOUCHER)}</span>
            <div className="text-sm" style={{ direction }}>
              <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
              {subtotalAfterVoucher.toFixed(currency.decimals)}
            </div>
          </div>
        </>
      )}

      {!isGift && (
        <div className="mb-3">
          {deliveryType !== DELIVERY_TYPE_ENUM.PICKUP && (
            <div className={cx('flex justify-between w-full pb-1', lang === 'ar' && 'flex-row-reverse')}>
              <span className="text-xs">{translate(DELIVERY_FEES)}</span>
              <div className="text-xs" style={{ direction }}>
                <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
                {deliveryFee.toFixed(currency.decimals)}
              </div>
            </div>
          )}
          {!taxInclusive && (
            <div className={cx('flex justify-between w-full pb-1', lang === 'ar' && 'flex-row-reverse')}>
              <span className="text-xs">{translate(TAXES(taxPercentage))}</span>
              <div className="text-xs" style={{ direction }}>
                <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
                {tax.toFixed(currency.decimals)}
              </div>
            </div>
          )}
        </div>
      )}

      {!isGift && (
        <div
          className={cx(
            'flex justify-between p-1 border-dashed border-gray-300 border-t w-full text-sm font-bold',
            lang === 'ar' && 'flex-row-reverse',
          )}
        >
          <span>{translate(TOTAL)}</span>
          <div style={{ direction }}>
            <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
            {total.toFixed(currency.decimals)}
          </div>
        </div>
      )}

      {!isGift && taxInclusive && (
        <div className={cx('flex justify-between w-full pb-1', lang === 'ar' && 'flex-row-reverse')}>
          <span className="text-sm">{translate(TAXES(taxPercentage))}</span>
          <div className="text-sm" style={{ direction }}>
            <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
            {tax.toFixed(currency.decimals)}
          </div>
        </div>
      )}

      {!isGift && (
        <div
          className={cx(
            'flex justify-between p-1 border-dashed border-gray-300 border-t w-full text-sm',
            lang === 'ar' && 'flex-row-reverse',
          )}
        >
          <span className={cx(lang === 'ar' ? 'ml-3' : 'mr-3')}>{translate(PAYMENT_ORDER)}</span>
          <span>{paidThroughMethod(paidThrough)}</span>
        </div>
      )}

      {!isGift && taxInclusive && (
        <div className={cx('flex w-full justify-center', lang === 'ar' && 'flex-row-reverse')}>
          <span className="text-sm">{translate(TAX_ARE_INCLUDED(taxPercentage))}</span>
        </div>
      )}
    </div>
  );
};

ThermalInvoicePricing.propTypes = {
  order: PropTypes.shape({
    discountedAmount: PropTypes.number.isRequired,
    voucherCode: PropTypes.string,
    subtotalAfterVoucher: PropTypes.number,
    deliveryType: PropTypes.string,
    deliveryFee: PropTypes.number,
    tax: PropTypes.number,
    taxPercentage: PropTypes.number,
    taxInclusive: PropTypes.bool,
    total: PropTypes.number.isRequired,
    paidThrough: PropTypes.string,
    voucherAmount: PropTypes.number,
  }),
  isGift: PropTypes.bool.isRequired,
  direction: PropTypes.string.isRequired,
  translate: PropTypes.func,
  lang: PropTypes.string.isRequired,
  store: PropTypes.shape({ currency: PropTypes.number.isRequired }),
  paidThroughMethod: PropTypes.func,
};
export default ThermalInvoicePricing;
