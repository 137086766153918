import React from 'react';
import { Typography, useTheme } from '@zydalabs/zac-react';

import QRCodeSVG from 'assets/QRCode/qr-code.svg';
import { QR_CODE_DESCRIPTION, QR_CODE_HEADER } from 'constants/translations';

type QRCodeBannerProps = {
  translate(item: Array<string>): string;
};

const QRCodeBanner: React.FC<QRCodeBannerProps> = ({ translate }) => {
  const { colors } = useTheme();
  return (
    <div className="p-8" style={{ backgroundColor: colors.warning['opacity-10'] }}>
      <div className="flex flex-row w-full justify-between">
        <div className="flex flex-col justify-center">
          <Typography variant="heading20">{translate(QR_CODE_HEADER)}</Typography>
          <Typography variant="body14">{translate(QR_CODE_DESCRIPTION)}</Typography>
        </div>

        <img src={QRCodeSVG} alt="qr-code" />
      </div>
    </div>
  );
};

export default QRCodeBanner;
