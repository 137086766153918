import React, { useContext } from 'react';
import { path } from 'ramda';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { context as notificationsContext } from 'context/notifications';
import { Text } from 'components/service';
import riderValidationSchema from 'components/common/verd/RiderForm/validationSchema';
import * as translations from 'constants/translations';

const RiderData = ({ initialValues, children, onSubmit }) => {
  const notifications = useContext(notificationsContext);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={riderValidationSchema}
      onSubmit={async data => {
        if (!data.name.trim()) {
          return notifications.show(<Text value={translations.NAME_SHOULD_CONTAIN_LETTERS} />, 'error');
        }
        const variables = {
          name: data.name,
          email: data.email,
          phone: data.phone.includes('+') ? data.phone : `+${data.phone}`,
          jobType: data.jobType,
          language: data.language,
          branches: data.branches,
          status: data.status,
        };
        try {
          await onSubmit(variables);
        } catch (err) {
          const errors = path(['graphQLErrors', 0, 'extensions', 'exception', 'body'], err);

          if (errors?.phone_number) {
            notifications.show(<Text value={translations.PHONE_IS_INVALID} />, 'error');
          }
        }
        return null;
      }}
    >
      {children}
    </Formik>
  );
};

export default RiderData;

RiderData.propTypes = {
  initialValues: {
    branches: PropTypes.arrayOf(PropTypes.string),
  },
  children: PropTypes.shape({ props: PropTypes.func }),
  onSubmit: PropTypes.func,
};
