import React, { useContext } from 'react';
import { Button, Typography } from '@zydalabs/zac-react';
import { ArrowRightIcon, ArrowLeftIcon } from '@zydalabs/zac-icons-react';

import { context as localeContext } from 'context/locale';
import ImageView from 'assets/engage-phone.svg';
import { Text } from 'components/service';
import { SEE_HOW_IT_WORKS, WHATSAPP_MARKET_AUTOPILOT, WHATSAPP_MARKET_AUTOPILOT_MESSAGE } from 'constants/translations';

const EngageWizard = () => {
  const { translate, lang, direction } = useContext(localeContext);

  return (
    <div className="bg-zyda-purple-50 p-5 md:flex md:flex-row-reverse items-center justify-between">
      <div>
        <img src={ImageView} alt="imageview" />
      </div>
      <div className="md:w-3/6 max-w-lg p-4" dir={direction}>
        <Typography variant="heading24">
          <Text value={WHATSAPP_MARKET_AUTOPILOT} />
        </Typography>

        <Typography variant="body16" mt={12}>
          <Text value={WHATSAPP_MARKET_AUTOPILOT_MESSAGE} />
        </Typography>
        <div className="mt-5 w-64">
          <Button
            isFluid
            text={translate(SEE_HOW_IT_WORKS)}
            variant="primary"
            rounded
            endIcon={lang === 'ar' ? <ArrowLeftIcon width="20px" /> : <ArrowRightIcon width="20px" />}
          />
        </div>
      </div>
    </div>
  );
};

export default EngageWizard;
