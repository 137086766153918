import React from 'react';

import { breadcrumbs } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import EngageWizard from './EngageWizard';
import ConversationCount from './ConversationCount';
import GeneratedSales from './GeneratedSales';
import RetentionProgress from './RetentionProgress';

const Engage = () => (
  <Layout top={<Breadcrumbs path={breadcrumbs.MARKETING_AUTO_PILOT} />}>
    <div className="md:flex justify-between gap-5">
      <div className="mb-5 md:w-3/6">
        <GeneratedSales />
      </div>

      <div className="mb-5 md:w-3/6">
        <ConversationCount />
      </div>
    </div>
    <div className="mb-5">
      <RetentionProgress />
    </div>

    <div>
      <EngageWizard />
    </div>
  </Layout>
);

export default Engage;
