import React, { useContext } from 'react';
import cx from 'classnames';
import { Tooltip, Tag, Typography } from '@zydalabs/zac-react';
import { ArrowRightCircleIcon, ArrowLeftCircleIcon } from '@zydalabs/zac-icons-react';

import ProgressArrow from 'assets/progress-arrow.svg';
import ProgressArrowAr from 'assets/progress-arrow-ar.svg';
import ProgressArrowDown from 'assets/progress-arrow-down.svg';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import { useMobile } from 'hooks';
import {
  ALL_TIME,
  FIRST_TIME_ORDER_VALUE,
  ORDER_PLACED,
  RETENTION_PROGRESS,
  SECOND_TIME_ORDER_VALUE,
  THIRD_TIME_ORDER_VALUE,
  TOTAL_VALUE,
} from 'constants/translations';

const RetentionArrow = ({ precentage, isMobile, lang }) => (
  <div
    className="flex items-center justify-center relative bg-top bg-contain bg-no-repeat"
    style={{
      backgroundImage: `url(${isMobile ? ProgressArrowDown : lang === 'ar' ? ProgressArrowAr : ProgressArrow})`,
      width: '90px',
      height: '90px',
    }}
  >
    <div style={{ marginInlineStart: isMobile ? '0' : lang === 'ar' ? '50%' : '-50%' }}>
      <Tag
        color="primary"
        inner={{
          text: `${precentage}%`,
          icon: lang === 'ar' ? <ArrowLeftCircleIcon /> : <ArrowRightCircleIcon />,
        }}
        size="small"
      />
    </div>
  </div>
);

const RetentionProgress = () => {
  const { lang, direction, translate } = useContext(localeContext);
  const isMobile = useMobile();
  const { selectedStore } = useContext(userContext);
  const { currency } = selectedStore;

  return (
    <div className="bg-gray-100 p-4">
      <div className="mb-4">
        <div className="flex justify-start items-center" dir={direction}>
          <Typography variant="heading14">
            <Text value={RETENTION_PROGRESS} className={cx(' w-auto', lang === 'ar' ? 'ml-2' : 'mr-2')} />
          </Typography>
          <div className="flex-shrink-0">
            <Tooltip
              info={<Text className="p-2" value={['info for tooltip', 'info for tooltip']} />}
              size="small"
              placement="bottom"
            />
          </div>
        </div>
        <Typography variant="body12">
          <Text value={ALL_TIME} />
        </Typography>
      </div>
      <div className={cx('gap-4 flex flex-col md:flex-row items-center justify-between', isMobile && 'text-center')}>
        <div>
          <div className="mb-3">
            <Typography variant="heading20">
              <div style={{ direction }}>
                <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
                {(10.391).toFixed(currency.decimals)}
              </div>
            </Typography>
            <Typography variant="heading12">
              <Text value={FIRST_TIME_ORDER_VALUE} />
            </Typography>
          </div>
          <div>
            <Typography variant="heading14">
              <Text value={['2,121', '2,121']} />
            </Typography>
            <Typography variant="element12">
              <Text value={ORDER_PLACED} />
            </Typography>
          </div>
        </div>
        <RetentionArrow precentage={30} isMobile={isMobile} lang={lang} />

        <div>
          <div className="mb-3">
            <Typography variant="heading20">
              <div style={{ direction }}>
                <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
                {(8.33).toFixed(currency.decimals)}
              </div>
            </Typography>
            <Typography variant="heading12">
              <Text value={SECOND_TIME_ORDER_VALUE} />
            </Typography>
          </div>
          <div>
            <Typography variant="heading14">
              <Text value={['1,640', '1,640']} />
            </Typography>
            <Typography variant="element12">
              <Text value={ORDER_PLACED} />
            </Typography>
          </div>
        </div>
        <RetentionArrow precentage={40} isMobile={isMobile} lang={lang} />

        <div>
          <div className="mb-3">
            <Typography variant="heading20">
              <div style={{ direction }}>
                <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
                {(22.33).toFixed(currency.decimals)}
              </div>
            </Typography>
            <Typography variant="heading12">
              <Text value={THIRD_TIME_ORDER_VALUE} />
            </Typography>
          </div>
          <div>
            <Typography variant="heading14">
              <Text value={['800', '800']} />
            </Typography>
            <Typography variant="element12">
              <Text value={TOTAL_VALUE} />
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetentionProgress;
